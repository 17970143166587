import { ContentBlock } from "@/components/AppBlocks";
import ApplyToAllSave from "@/components/ApplyToAllSave";
import Breadcrumb from "@/components/Breadcrumb";
import FormBar from "@/components/FormBar";
import PageHeading from "@/components/PageHeading";
import { P } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import { applyDefaultIntegrations, toggleApplyToAllModal } from "@/state/actions/applyToAllActions";
import { getUser, saveUser } from "@/state/actions/userActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { InjectedFormProps, formValueSelector, reduxForm } from "redux-form";
import { IntegrationsFields, IntegrationSettings } from "./IntegrationsFields";

const formName = "integrationsForm";
const selector = formValueSelector(formName);

type IntegrationsFormComponentProps = PropsFromRedux & InjectedFormProps & { userId?: string };

const IntegrationsFormComponent: FC<IntegrationsFormComponentProps> = ({ userId, handleSubmit }) => {
	const isAdmin = AuthService.isAdmin();

	const dispatch = useAppDispatch();
	const currentUser = useAppSelector((state) => state.currentUser);
	const googleAnalyticsId = useAppSelector((state) => selector(state, "googleAnalyticsId"));
	const googleTagManagerId = useAppSelector((state) => selector(state, "googleTagManagerId"));

	// The TikTok Pixel integration must be enabled by admins from 22 November 2024. See https://humanitix.atlassian.net/browse/JEN-15464 / https://humanitix.atlassian.net/browse/JEN-17704
	// Users who have already added a TikTok Pixel ID in the ~2 months the integration has been live will be allowed to continue using the integration.
	const showTiktokIntegration =
		isAdmin ||
		currentUser?.user?.isTiktokPixelIntegrationEnabled ||
		currentUser?.user?.defaultIntegrationSettings?.tiktokPixelId;

	const load = () => {
		dispatch(getUser(userId));
	};

	useEffect(() => {
		load();
	}, []);

	const save = (values: IntegrationSettings) => {
		dispatch(
			saveUser(userId, {
				defaultIntegrationSettings: values,
				applyToAllRequired: true
			})
		);
		dispatch(toggleApplyToAllModal({ stateToApplyToModal: true }));
	};

	const handleApplyDefaultIntegrations = () => {
		dispatch(applyDefaultIntegrations(isAdmin ? currentUser?.user?.id : undefined));
	};

	return (
		<>
			<Breadcrumb
				prevPath="/console/account/advanced/integrations"
				prevTitle="Integrations"
				currTitle="Default tracking IDs"
			/>
			<PageHeading
				title="Default tracking IDs"
				helpButton={{
					link: HelpIds.analysingEventTraffic,
					title: "Help - Analysing Event Traffic"
				}}
			/>
			<form onSubmit={handleSubmit(save)}>
				<ContentBlock>
					<P style={{ marginBottom: 0 }}>
						Set up default tracking IDs that automatically apply to all your events. You can override these settings for
						individual events by editing your event.
					</P>
					<hr />
					<IntegrationsFields
						isGlobal
						loading={currentUser.loading}
						error={currentUser.error}
						googleAnalyticsId={googleAnalyticsId}
						googleTagManagerId={googleTagManagerId}
						showTiktokIntegration={showTiktokIntegration}
					/>
				</ContentBlock>
				<FormBar>
					<ApplyToAllSave onOk={handleApplyDefaultIntegrations} />
				</FormBar>
			</form>
		</>
	);
};

const mapStateToProps = (state: GlobalReduxState) => {
	return {
		initialValues: state.currentUser?.user?.defaultIntegrationSettings ?? {}
	};
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const FormWithRedux = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(IntegrationsFormComponent);

const Integrations = connector(FormWithRedux);

export { Integrations };
