import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getConfig } from "@/config";
import withSaveCheck from "@/hoc/withSaveCheck";
import { CypressService, IS_SUCCESS } from "@/services/CypressService";
import { updateEventTicketTypes } from "@/state/actions/eventActions";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors, scrollToId } from "@/utils/ScrollToErrors";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router";
import { change, formValueSelector, reduxForm } from "redux-form";
import FinishLater from "../../eventCreation/FinishLater";
import StepBackButton from "../../eventCreation/StepBackButton";
import { wrapperMenu, wrapperNoMenu } from "../../eventCreation/utils/commonStyles";
import { checkInWizard } from "../../eventCreation/utils/eventCreationUtils";
import Donations from "./donations/Donations";
import Tickets from "./tickets/Tickets";

function cleanTicketType(ticketType: any) {
	if (ticketType.isDonation && ticketType.donationOptionsOn) {
		ticketType.priceRange = { enabled: false };
	} else if (ticketType.isDonation && !ticketType.donationOptionsOn) {
		ticketType.priceRange = { ...ticketType.priceRange, enabled: true };
	}
	return ticketType;
}

const TicketTypes = ({
	updateEventTicketTypes,
	currentEvent,
	dispatch,
	touch,
	handleSubmit,
	customCapacity,
	array,
	ticketTypes,
	secondaryTicketTypes
}: any) => {
	const location = useLocation();
	const params = useParams();

	const [check, setCheck] = useState(false);
	const changeFieldValue = (field: string, value: any) => dispatch(change(formName, field, value));
	const timezone = currentEvent?.event?.timezone ?? getConfig("TIMEZONE_DEFAULT");
	const inWizard = checkInWizard(currentEvent?.event);

	useEffect(() => CypressService.ticketTypesMount(IS_SUCCESS), []);

	const isPartOfSteps = location.pathname?.indexOf("ticket-types") !== -1;

	const _save = (values: any) => {
		const eventId = params?.eventId;

		values.ticketTypes = values?.ticketTypes?.length ? values.ticketTypes?.map(cleanTicketType) : null;

		if (!values.ticketTypes?.length) {
			scrollToId("tickets");
			setCheck(true);
			return;
		}
		const ticketTypesToSave = values.ticketTypes.concat(
			values.secondaryTicketTypes?.length ? values.secondaryTicketTypes.map(cleanTicketType) : []
		);
		updateEventTicketTypes(
			eventId,
			{
				totalCapacity: values.totalCapacity,
				ticketTypes: ticketTypesToSave,
				customCapacity: values.customCapacity,
				finishLater: values.finishLater,
				defaultAdditionalQuestion: values.defaultAdditionalQuestion
			},
			isPartOfSteps
		);
	};

	return (
		<form onSubmit={handleSubmit(_save)} className={inWizard ? wrapperNoMenu : wrapperMenu}>
			{!isPartOfSteps && (
				<PageHeading title="Ticket types" helpButton={{ title: "Help - Images", link: HelpIds.ticketTypes }} />
			)}

			<LoadErrorView loading={currentEvent.loading} error={currentEvent.error} retryAction={handleSubmit(_save)}>
				{currentEvent?.event ? (
					<>
						<Tickets
							ticketTypes={ticketTypes}
							secondaryTicketTypes={secondaryTicketTypes}
							customCapacity={customCapacity}
							timezone={timezone}
							changeFieldValue={changeFieldValue}
							touch={touch}
							check={check}
							array={array}
							inline={false}
							inWizard={inWizard}
							formName={formName}
							dispatch={dispatch}
							hasSecondary={currentEvent?.event?.settings?.isSecondaryTicketsEnabled}
						/>

						<Donations
							ticketTypes={ticketTypes}
							timezone={timezone}
							changeFieldValue={changeFieldValue}
							touch={touch}
							isFreeOnly={currentEvent?.event?.isFreeOnly}
						/>
					</>
				) : null}

				<FormBar>
					<FinishLater saveFunction={_save} handleSubmit={handleSubmit} />
					<StepBackButton inWizard={inWizard} isPartOfSteps={isPartOfSteps} />
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						id="submitButton"
						ariaLabel="Save"
						size="large"
						dataCy="next-step"
					>
						{isPartOfSteps && inWizard ? "Save draft" : "Save"}
					</LegacyButton>
				</FormBar>
			</LoadErrorView>
		</form>
	);
};
const formName = "ticketsPage";

const ReduxConnectedComponent = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => scrollToErrors()
})(TicketTypes);

const selector = formValueSelector(formName);
export default connect(
	(state: Partial<GlobalReduxState>) => {
		return {
			initialValues: state?.currentEvent?.event
				? {
						totalCapacity: state.currentEvent.event.totalCapacity,
						ticketTypes: state.currentEvent.event.ticketTypes?.filter((ticketType: any) => !ticketType.isSecondary),
						secondaryTicketTypes: state.currentEvent.event.ticketTypes?.filter(
							(ticketType: any) => ticketType.isSecondary
						),
						customCapacity: state.currentEvent.event.customCapacity,
						finishLater: state.currentEvent.event.finishLater,
						defaultAdditionalQuestion: state.currentEvent.event.defaultAdditionalQuestion
				  }
				: undefined,
			currentEvent: state.currentEvent,
			ticketTypes: selector(state, "ticketTypes"),
			secondaryTicketTypes: selector(state, "secondaryTicketTypes"),
			customCapacity: selector(state, "customCapacity")
		};
	},
	{ updateEventTicketTypes }
)(withSaveCheck(ReduxConnectedComponent, formName));
